@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
	from {
		margin-top: -50px;
	}
	to {
		margin-top: 0px;
	}
}

@keyframes arrowLeft {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
	from {
		margin-left: -50px;
	}
	to {
		margin-left: 0px;
	}
}

@keyframes arrowRight {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
	from {
		margin-right: -50px;
	}
	to {
		margin-right: 0px;
	}
}

@keyframes arrowRightItem {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
	from {
		margin-right: -100px;
	}
	to {
		margin-right: -80px;
	}
}

@keyframes arrowLeftItem {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
	from {
		margin-left: -100px;
	}
	to {
		margin-left: -80px;
	}
}

.fadeIn {
	animation: 500ms ease-in-out 0s fadeIn;
}

.animation-arrow-left-main {
	animation: 500ms ease-in-out 0s arrowLeft;
}

.animation-arrow-right-main {
	animation: 500ms ease-in-out 0s arrowRight;
}

.animation-arrow-right-item {
	animation: 500ms ease-in-out 0s arrowRightItem;
}

.animation-arrow-left-item {
	animation: 500ms ease-in-out 0s arrowLeftItem;
}

.slick-prev:before,
.slick-next:before {
	color: #7a7a3cad !important;
}
